import * as React from "react"
import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <>
    <Helmet>
      <title>ASD Yuan - Pagina non trovata</title>
      <meta
        name="description"
        content="Errore 404 - Spiacenti questa pagina non esiste"
      />
      <link rel="canonical" href="https://www.taichiyuan.it/404/" />
    </Helmet>
  </>
)

export default NotFoundPage
